<template>
    <div class="special_page">
        <Banner :adCategory="8" :big="1"></Banner>
        <div class="item_div  anotherScopeServicesStyle">
            <div class="content">
                <div class="title display">{{ infoData.serviceScopeName }}</div>

                <div class="scopeServices_cont anotherStyle display">
                    <div class="list " v-for="(item, index) in infoData.renovationWaterproofingServiceScopes"
                        :key="index">
                        <div class="imgBox" v-if="index % 2 == 0">
                            <img :src="item.serviceScopeImage" alt="">
                        </div>
                        <div class="cont flex-center column">
                            <div class="scopeServices_name">{{ item.serviceScopeName }}</div>
                            <div class="scopeServices_text">{{ item.serviceScopeIntroduce }}</div>
                            <div class="contactUs display" @click="service">联系我们</div>
                        </div>
                        <div class="imgBox" v-if="index % 2 != 0">
                            <img :src="item.serviceScopeImage" alt="">
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="item_div serviceCase">
            <div class="content">
                <div class="title display">{{ infoData.serviceCaseName }}</div>
                <div class="subheading display">{{ infoData.serviceCaseInfo }}</div>
                <div class="slideshow">
                    <el-carousel indicator-position="outside" :interval="3000">
                        <el-carousel-item class="el-car-item" v-for="(item, index) in infoData.serviceCaseImage"
                            :key="index">
                            <div class="carouselData display">
                                <img class="certificate" :src="item" alt="" />
                            </div>
                        </el-carousel-item>
                    </el-carousel>
                </div>
            </div>
        </div>
        <div class="item_div companyProfile">
            <div class="content">
                <div class="title display">{{ infoData.companyProfileName }}</div>
                <div class="companyProfileBox Between">
                    <div class="companyProfileDetails">
                        <div class="company_name">{{ infoData.companyName }}</div>
                        <div class="line"></div>
                        <div class="companyProfile_info">
                            {{ infoData.companyProfile }}
                        </div>
                    </div>
                    <div class="companyImg_box">
                        <img :src="infoData.companyImage" alt="">
                    </div>
                </div>
                <div class="setUp Between">
                    <div class="list flex-center column"
                        v-for="(item, index) in infoData.renovationWaterproofingCompanyProfiles" :key="index">
                        <div class="num_div flex">
                            <div class="num">{{ item.companyValue }}</div>
                            <div class="unit">{{ item.companyUnit }}</div>
                        </div>
                        <div class="text">{{ item.companyIntroduce }}</div>
                    </div>
                </div>
                <div class="btn display" @click="service">了解更多</div>
            </div>
        </div>
        <div class="item_div honorQualification">
            <div class="content">
                <div class="title display">{{ infoData.honorQualificationName }}</div>
                <div class="slideshow">
                    <el-carousel indicator-position="outside" :interval="3000">
                        <el-carousel-item class="el-car-item" v-for="(item, index) in infoData.honorQualificationImage"
                            :key="index">
                            <div class="carouselData display">
                                <img class="certificate" :src="item" alt="" />
                            </div>
                        </el-carousel-item>
                    </el-carousel>
                </div>
            </div>
        </div>
        <div class="item_div focusBuildingProtection">
            <div class="content">
                <div class="title display">{{ infoData.protectionDomainName }}</div>
                <div class="subheading display">{{ infoData.protectionDomainInfo }}</div>
                <div class="focusBuildingProtection_box Between flex-wrap">
                    <div class="list flex" v-for="(item, index) in infoData.renovationWaterproofingProtectionDomains"
                        :key="index">
                        <div class="imgBox">
                            <img :src="item.protectionDomainImage" alt="">
                        </div>
                        <div class="cont flex-center column">
                            <div class="name">{{ item.protectionDomainName }}</div>
                            <div class="text">{{ item.protectionDomainIntroduce }}</div>
                            <div class="knowMoreAbout display">了解更多>></div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="item_div standardProcedure">
            <div class="content">
                <div class="title display">{{ infoData.serviceProcessName }}</div>
                <div class="standardProcedure_box flex">
                    <div class="list display column"
                        v-for="(item, index) in infoData.renovationWaterproofingServiceProcessS" :key="index">
                        <img class="arrows_img" src="@/assets/img/special/arrows.png" alt="">
                        <img :src="item.serviceProcessImage" alt="">
                        <div class="text">{{ item.serviceProcessName }}</div>
                    </div>

                </div>
            </div>
        </div>
        <div class="item_div cooperativePartner">
            <div class="content">
                <div class="title display">{{ infoData.businessPartnerName }}</div>
                <div class="cooperativePartner_box">
                    <img :src="infoData.businessPartnerImage" alt="">
                </div>
            </div>
        </div>

        <div class="item_div news">
            <div class="content">
                <div class="title display">{{ infoData.newsInformationName }} </div>
                <div class="navBar_page">
                    <div class="navBar display">
                        <div class="navBar_list display pointer" v-for="(item, index) in infoData.newsVOS" :key="index"
                            @click="changeNewsNavBar(item, index)" :class="navBarIndex == index ? 'navBarActive' : ''">
                            {{ item.name }}
                        </div>
                    </div>
                </div>
                <NewsList :newsList="newsList.value" :fatherId="newsList.index + 1" v-if="newsList.name != '视频中心'">
                </NewsList>
                <div class="videoList_box" v-else>
                    <div class="classify_box display">
                        <div class="classify_list pointer display"
                            :class="classifyIndex == index ? 'classifyActive' : ''"
                            v-for="(item, index) in classifyList" :key="index" @click="changeNavBtn(item, index)">
                            {{ item.name }}
                        </div>
                    </div>
                    <div class="video_mian">
                        <VideoList :videoList="videoList"></VideoList>
                    </div>
                </div>
                <div @click="newsViewBtn" class="view_more display pointer">
                    点击查看更多
                </div>
            </div>
        </div>

    </div>
</template>
<script>
import Banner from '@/components/banner'
import NewsList from '@/components/newsList'
import VideoList from './news/components/videoList'
export default {
    components: {
        Banner,
        NewsList,
        VideoList,
    },
    data() {
        return {
            list: [
                {},
                {},
                {},
                {},
            ],
            newsList: [],
            navBarIndex: 0,
            classifyList: [
                {
                    id: 1,
                    name: '企业视频',
                },
                {
                    id: 2,
                    name: '产品介绍',
                },
                {
                    id: 3,
                    name: '解决方案',
                },
                {
                    id: 4,
                    name: '工程案例',
                },
            ],
            videoList: [],
            paramsVideo: {
                page: 1,
                limit: 4,
                videoType: 1,
            },
            total: 0,
            classifyIndex: 0,
            infoData: {},
        }
    },
    created() {
        this.getData()
    },
    methods: {
        //获取专业防水数据
        getData() {
            this.api.getRenovationWaterproofingInfo({ type: 1 }).then((res) => {
                if (res.code == 0) {
                    this.infoData = res.data
                    this.infoData.serviceCaseImage = this.infoData.serviceCaseImage.split(',')
                    this.infoData.honorQualificationImage = this.infoData.honorQualificationImage.split(',')
                    this.newsList = this.infoData.newsVOS[0]
                    this.newsList.value.forEach((item) => {
                        item.createTime = item.createTime.slice(0, 10)
                    })
                    this.newsList.index = 0
                }
            })
        },

        //点击新闻资讯分类
        changeNewsNavBar(item, index) {
            console.log(item)
            if (item.name == '视频中心') {
                this.getVideoData()
            } else {
                item.value.forEach((data) => {
                    data.createTime = data.createTime.slice(0, 10)
                })
            }
            item.index = index
            this.navBarIndex = index
            this.newsList = item
        },
        //获取新闻视频
        getVideoData() {
            this.api.findPageVideo(this.paramsVideo).then((res) => {
                console.log(res)
                this.videoList = res.data
                this.total = res.count
            })
        },
        // 点击新闻视频分类
        changeNavBtn(item, index) {
            this.paramsVideo.videoType = item.id
            this.classifyIndex = index
            this.getVideoData()
        },
        //更多新闻
        newsViewBtn() {
            let obj = {
                ids: 6,
                id: this.newsList.index + 1,
                name: this.newsList.name,
                index: this.newsList.index,
                fatherName: '',
                isReturn: 3,
                classifyIndex: this.classifyIndex,
            }
            this.$store.commit('getTypeId', this.newsList.index + 1)
            localStorage.setItem('navBarData', JSON.stringify(obj))
            let routeData = this.$router.resolve({
                path: '/news/index',
                query: obj,
            })
            window.open(routeData.href, '_blank')
        },
        //联系客服
        service() {
            document.querySelector('.embed-chat-container').style.height = '596px'
            document.querySelector('.embed-chat-content').style.display = 'block'
        },
    }
}
</script>
<style lang="scss" scoped>
.special_page {
    .item_div {
        .title {
            font-family: Microsoft YaHei;
            font-weight: bold;
            font-size: 36px;
            color: #333333;
            line-height: 36px;
            padding-top: 96px;
        }

        .subheading {
            font-family: Microsoft YaHei;
            font-weight: 400;
            font-size: 18px;
            color: #666666;
            line-height: 18px;
            margin-top: 22px;
        }
    }

    .scopeServices {
        width: 100%;
        height: 740px;
        background: url('../assets/img/special/bg1.png');
        background-repeat: no-repeat;
        background-size: 100% 100%;

        .scopeServices_cont {
            margin-top: 60px;

            .list {
                width: 280px;
                height: 450px;
                background: #FFFFFF;
                box-shadow: 0px 1px 10px 0px rgba(0, 0, 0, 0.15);
                margin-left: 25px;

                .imgBox {
                    width: 280px;
                    height: 200px;

                    img {
                        width: 100%;
                        height: 100%;
                    }

                }

                .scopeServices_name {
                    font-family: Microsoft YaHei;
                    font-weight: 400;
                    font-size: 24px;
                    color: #333333;
                    line-height: 24px;
                    margin-top: 30px;
                }

                .line {
                    width: 26px;
                    height: 3px;
                    background: #E62129;
                    margin: 20px 0;
                }

                .scopeServices_text {
                    width: 224px;
                    height: 63px;
                    font-family: Microsoft YaHei;
                    font-weight: 400;
                    font-size: 16px;
                    color: #666666;
                    line-height: 24px;
                    text-align: center;
                }

                .contactUs {
                    width: 130px;
                    height: 40px;
                    border-radius: 19px;
                    border: 1px solid #E62129;
                    font-family: Microsoft YaHei;
                    font-weight: 400;
                    font-size: 18px;
                    color: #E62129;
                    line-height: 18px;
                    margin-top: 25px;
                    cursor: pointer
                }
            }

            .list:nth-child(1) {
                margin-left: 0 !important;
            }
        }


    }

    .anotherScopeServicesStyle {
        width: 100%;
        height: 770px;
        background: #fafafa;

        .scopeServices_cont {
            margin-top: 60px;

            .list {
                width: 300px;
                height: 500px;
                background: #FAFAFA;

                .imgBox {
                    width: 300px;
                    height: 250px;

                    img {
                        width: 100%;
                        height: 100%;
                    }

                }

                .cont {
                    height: 250px;

                    .scopeServices_name {
                        font-family: Microsoft YaHei;
                        font-weight: 400;
                        font-size: 24px;
                        color: #333333;
                        line-height: 24px;
                        margin-top: 48px;
                        margin-bottom: 28px;
                    }

                    .scopeServices_text {
                        width: 224px;
                        height: 63px;
                        font-family: Microsoft YaHei;
                        font-weight: 400;
                        font-size: 16px;
                        color: #666666;
                        line-height: 24px;
                        text-align: center;
                    }

                    .contactUs {
                        width: 130px;
                        height: 40px;
                        border-radius: 19px;
                        border: 1px solid #E62129;
                        font-family: Microsoft YaHei;
                        font-weight: 400;
                        font-size: 18px;
                        color: #E62129;
                        line-height: 18px;
                        margin-top: 25px;
                        cursor: pointer;
                    }
                }
            }
        }


    }

    .serviceCase {
        width: 100%;
        height: 1060px;

        .slideshow {
            margin-top: 32px;

            ::v-deep.el-car-item {
                width: 100%;
                display: flex;
            }

            ::v-deep.el-carousel {
                .el-carousel__container {
                    height: 730px;

                    .el-carousel__arrow {
                        width: 60px;
                        height: 60px;
                        font-size: 24px;
                        background: rgba(51, 51, 51, 0.5);
                        display: flex;
                        align-items: center;
                        justify-content: center;
                    }
                }
            }

            ::v-deep.el-carousel__indicators {
                .el-carousel__button {
                    width: 58px;
                    height: 8px;
                    opacity: 1;
                    border-radius: 6px;
                    background: #DDDDDD;
                }

                .el-carousel__indicator.is-active button {
                    width: 58px;
                    height: 8px;
                    background: #e62129;
                    border-radius: 6px;
                }
            }

            ::v-deep .el-carousel__arrow {
                display: none !important;
            }

            .carouselData:nth-child(1) {
                margin-left: 0px !important;
            }

            .carouselData {
                width: 1200px;

                img {
                    width: 1200px;
                    height: 690px;
                }

                .name {
                    font-size: 14px;
                    font-family: Microsoft YaHei;
                    font-weight: 400;
                    color: #666666;
                    margin-top: 10px;
                }
            }
        }

    }

    .companyProfile {
        height: 910px;
        background: url('../assets/img/special/bg2.png');
        background-repeat: no-repeat;
        background-size: 100% 100%;

        .companyProfileBox {
            margin-top: 70px;

            .companyProfileDetails {
                padding-top: 39px;

                .company_name {
                    font-family: Microsoft YaHei;
                    font-weight: bold;
                    font-size: 24px;
                    color: #333333;
                    line-height: 24px;
                }

                .line {
                    width: 50px;
                    height: 4px;
                    background: #E62129;
                    margin-top: 34px;
                    margin-bottom: 26px;
                }

                .companyProfile_info {
                    width: 528px;
                    font-family: Microsoft YaHei;
                    font-weight: 400;
                    font-size: 18px;
                    color: #333333;
                    line-height: 36px;
                }

            }

            .companyImg_box {
                width: 600px;
                height: 380px;
                border-radius: 5px;

                img {
                    width: 100%;
                    height: 100%;
                    border-radius: 5px;
                }
            }
        }

        .setUp {
            margin-top: 60px;
            padding-left: 11px;
            padding-right: 20px;
            box-sizing: border-box;

            .list {

                .num_div {

                    .num {
                        font-family: Microsoft YaHei;
                        font-weight: bold;
                        font-size: 54px;
                        color: #E62129;
                        line-height: 54px;
                    }

                    .unit {
                        font-family: Microsoft YaHei;
                        font-weight: 400;
                        font-size: 18px;
                        color: #E62129;
                        line-height: 18px;
                        margin-top: 24px;
                        margin-left: 9px;
                    }
                }

                .text {
                    font-family: Microsoft YaHei;
                    font-weight: 400;
                    font-size: 18px;
                    color: #666666;
                    line-height: 18px;
                    margin-top: 24px;
                }
            }
        }

        .btn {
            width: 260px;
            height: 48px;
            background: #E62129;
            border-radius: 5px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            font-size: 20px;
            color: #FFFFFF;
            line-height: 24px;
            margin: 0 auto;
            margin-top: 60px;
            cursor: pointer;
        }
    }

    .honorQualification {
        height: 698px;

        .slideshow {
            margin-top: 32px;

            ::v-deep.el-car-item {
                width: 100%;
                display: flex;
            }

            ::v-deep.el-carousel {
                .el-carousel__container {
                    height: 400px;

                    .el-carousel__arrow {
                        width: 60px;
                        height: 60px;
                        font-size: 24px;
                        background: rgba(51, 51, 51, 0.5);
                        display: flex;
                        align-items: center;
                        justify-content: center;
                    }
                }
            }

            ::v-deep.el-carousel__indicators {
                .el-carousel__button {
                    width: 58px;
                    height: 8px;
                    opacity: 1;
                    border-radius: 6px;
                    background: #DDDDDD;
                }

                .el-carousel__indicator.is-active button {
                    width: 58px;
                    height: 8px;
                    background: #e62129;
                    border-radius: 6px;
                }
            }

            ::v-deep .el-carousel__arrow {
                display: none !important;
            }

            .carouselData:nth-child(1) {
                margin-left: 0px !important;
            }

            .carouselData {
                width: 1200px;

                img {
                    width: 1200px;
                    height: 378px;
                }

                .name {
                    font-size: 14px;
                    font-family: Microsoft YaHei;
                    font-weight: 400;
                    color: #666666;
                    margin-top: 10px;
                }
            }
        }

    }

    .focusBuildingProtection {
        width: 100%;
        height: 1060px;
        background: url('../assets/img/special/bg3.png');
        background-repeat: no-repeat;
        background-size: 100% 100%;

        .title {
            color: white;
        }

        .subheading {
            color: white;
        }

        .focusBuildingProtection_box {
            margin-top: 60px;

            .list {
                width: 590px;
                height: 219px;
                background: white;
                box-sizing: border-box;
                margin-bottom: 20px;

                .imgBox {
                    width: 319px;
                    height: 220px;

                    img {
                        width: 100%;
                        height: 100%;
                    }
                }

                .cont {
                    padding-top: 40px;
                    padding-left: 40px;
                    box-sizing: border-box;

                    .name {
                        font-family: Microsoft YaHei;
                        font-weight: bold;
                        font-size: 24px;
                        color: #333333;
                        line-height: 24px;
                        margin-bottom: 20px;
                    }

                    .text {
                        width: 223px;
                        height: 46px;
                        font-family: Microsoft YaHei;
                        font-weight: 400;
                        font-size: 16px;
                        color: #333333;
                        line-height: 24px;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        display: -webkit-box;
                        -webkit-line-clamp: 3;
                        -webkit-box-orient: vertical;
                    }

                    .knowMoreAbout {
                        width: 131px;
                        height: 41px;
                        border-radius: 20px;
                        border: 1px solid #E62129;
                        font-family: Microsoft YaHei;
                        font-weight: 400;
                        font-size: 16px;
                        color: #E62129;
                        line-height: 24px;
                        margin-top: 26px;
                        cursor: pointer
                    }
                }
            }
        }
    }

    .standardProcedure {
        height: 443px;

        .standardProcedure_box {
            margin-top: 69px;

            .list {
                margin-right: 108px;
                position: relative;

                img {
                    width: 96px;
                    height: 96px;
                    border-radius: 50%;
                }

                .arrows_img {
                    width: 22px;
                    height: 25px;
                    position: absolute;
                    top: 33px;
                    right: -62px;
                }

                .text {
                    font-family: Microsoft YaHei;
                    font-weight: 400;
                    font-size: 20px;
                    color: #333333;
                    line-height: 20px;
                    margin-top: 28px;
                }
            }

            .list:last-child {
                margin-right: 0 !important;

                .arrows_img {
                    display: none;
                }
            }
        }
    }

    .cooperativePartner {
        width: 100%;
        height: 661px;
        background: url('../assets/img/special/bg4.png');
        background-repeat: no-repeat;
        background-size: 100% 100%;

        .cooperativePartner_box {
            margin-top: 41px;

            img {
                width: 100%;
                height: 376px;
            }
        }
    }

    .news {
        padding-bottom: 100px;

        .view_more {
            width: 250px;
            height: 48px;
            background: #e62129;
            font-size: 18px;
            color: #ffffff;
            margin: 0 auto;
            margin-top: 58px;
            cursor: pointer
        }

        .videoList_box {
            .classify_box {
                margin-top: 34px;

                .classify_list {
                    width: 126px;
                    height: 20px;
                    font-size: 16px;
                    color: #333333;
                    border-right: 1px solid #dedede;
                }

                .classify_list:last-child {
                    border-right: none !important;
                }

                .classifyActive {
                    color: #e62129 !important;
                }
            }

            .video_mian {
                margin-top: 30px;
            }
        }

        .video_mask_layer {
            .mask_layer {
                width: 100%;
                height: 100%;
                background: rgba(0, 0, 0, 0.48);
                position: fixed;
                top: 0px;
                left: 0px;
                z-index: 1000000;
            }

            .video_cont {
                width: 1100px;
                height: 700px;
                background: white;
                position: fixed;
                top: 50%;
                left: 50%;
                margin-top: -350px;
                margin-left: -550px;
                z-index: 1000001;
                padding: 40px;
                box-sizing: border-box;

                .videoCont {
                    width: 100%;
                    height: 100%;
                }

                .offBtn {
                    width: 25px;
                    height: 25px;
                    position: absolute;
                    top: 0;
                    right: 0;
                }
            }
        }

        .navBar {
            margin-top: 29px;

            .navBar_list {
                width: 124px;
                height: 46px;
                background: #ffffff;
                border: 1px solid #dedede;
                font-size: 16px;
                font-family: Microsoft YaHei;
                font-weight: 400;
                color: #333333;
                margin: 0px 10px;
            }

            .navBarActive {
                background: #e62129 !important;
                border: 1px solid #e62129 !important;
                color: #fff !important;
            }
        }

        .video_div {
            position: relative;

            .off {
                width: 40px;
                height: 40px;
                position: absolute;
                top: 43px;
                right: 0;
                z-index: 2;
            }
        }
    }
}
</style>